import React, { useRef, useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import image from "./PrintTemplateImage.png"
import { useParams,useHistory } from 'react-router-dom'
import "./PrintTemplateCSSAppointment.css"
import { Grid } from '@mui/material';
import axios from '../../helper/axios';
import moment from 'moment'
import API from "../../helper/axios";
const PrintTemplate = (props) => {
    const history = useHistory();
    const params = useParams()
    const today = new Date()
    const token = localStorage.getItem("jwtToken");
    const [patientData, setPatientData] = useState([{
        appointmentdate:""
    }])
    console.log(patientData)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: patientData.opdrecordnumber,
    onAfterPrint:  props.handleClosed ,
    });
//get the Patient details according to patientid
    const getPatientDetails = async() => {
        const res = await axios.get(`/GetPatientBy/${props.pId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(res.data[0])
          setPatientData(res.data[0])
    } 
    useEffect(() => {
        getPatientDetails()
        return () => {}
    },[])
    const [data, setData] = useState([]);
    const [userHospitalcode, setUserHospitalcode] = useState();
    const [userrole1, setUserrole1] = useState();
    const [isAuthorized, setIsAuthorized] = useState(false);
    // Function to get login user's details
    const loadAllDetails = async () => {
      try {
        const response = await axios.post('/loginuser', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        // Debugging: Log the entire response
        console.log("Response:", response);
    
        const UserRoles = response.data.person[0][0].role1;
        const hospitalcode = response.data.person[0][0].hospitalcode;
    
        // Debugging: Log the values of userHospitalcode and userrole1
        console.log("userHospitalcode:", hospitalcode);
        console.log("userrole1:", UserRoles);
    
        setUserHospitalcode(hospitalcode);
        setUserrole1(UserRoles);
        // Debugging: Log the condition result
        const authorizationResult = hospitalcode === 1 && UserRoles === 'Superadmin';
        console.log("Authorization Result:", authorizationResult);
        setIsAuthorized(authorizationResult);
        console.log("isAuthorized after setIsAuthorized:", isAuthorized);
      } catch (error) {
        console.error("Error:", error);
    
        if (error.response.status === 403) {
          alert('Token Expired. Please login again.');
          localStorage.removeItem('jwtToken');
          history.push('/');
        } else if (error.response.status === 401) {
          alert('Invalid token. Please login again.');
          localStorage.removeItem('jwtToken');
          history.push('/');
        } else {
          alert('Error: ' + error.response.data.error);
        }
      }
    };
    //when page load or refresh happen this function will got called
    useEffect(() => {
      const loadData = async () => {
        await loadAllDetails();
        //loadHospitalLogo(); will wait for loadAllDetails(); to got execute
        await loadHospitalLogo();
      };
      loadData();
    }, [userHospitalcode, userrole1, isAuthorized]);
     //get all hospital code
    const loadHospitalLogo = async () => {
      //when userHospitalcode is not undefined
      if (userHospitalcode !== undefined ) {
        console.log(userHospitalcode)
        const res = await axios.get(`/gethospitallogo/${patientData.hospitalcode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            hospitalcode: patientData.hospitalcode, // Include the hospitalcode in the query parameters
            //userrole: userrole1, // Include the userrole in the query parameters
          },
        });
        setData(res.data[0]);
        console.log(res.data);
      };
    }
    return(
        <>
        <button className="btn btn-success" style={{marginLeft: "68%", backgroundColor: "white", color: "green"}} onClick={handlePrint}>Print Prescription</button>
            <div
                // className="pass-title"
                ref={componentRef}
                style={{ width: '100%', height: window.innerHeight, padding: "40px" }}
            >
                <img src={`${API.defaults.baseURL}/uploadimage/attachone/${data.attachment1
                    }`} style={{width: '100%', marginBottom: "1px", height:"23%"}}/>
                {/* <lable><span style={{fontWeight: "bolder", fontSize: "10px", width: "100%", marginBottom: "1px"}}>* OPD Timining : 9AM-3PM.For Emergency 24*7</span>
                  &nbsp;&nbsp;&nbsp;<span style={{fontWeight: "bold", fontSize: "10px"}}>* हर रिववार ओपीडी बंद रहता है , लेिकन िनक खुला रहेगा।</span>&nbsp;&nbsp;&nbsp;<span style={{fontSize: "10px"}}>  :9708162912, 06155295038, 06155231824</span></lable> */}
                <div style={{textAlign: "center", marginTop: "1px"}}>
                    <b>OPD Prescription</b>
                </div>
                <div class="box"></div>
                <Grid container spacing={3} style={{fontSize: "12px"}}>
                    <Grid item xs={4}>
                        <b>UHID :-    </b>{patientData.patientid}
                    </Grid>
                    <Grid item xs={4}>
                        <b>OPD No. :-    </b>{patientData.opdnumber}
                    </Grid>
                    <Grid item xs={4}>
                    <b>Mobile:-    </b>{patientData.phoneno}
                    </Grid>
                    {/* <Grid item xs={2}>
                        {patientData.phoneno}
                    </Grid> */}
                </Grid>
                {/* <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item>
                        <b>OPD No {patientData.opdnumber}</b>
                    </Grid>
                    <Grid item>
                        <b>Date: {today.getMonth()+1+"/"+today. getDate()+"/"+today.getFullYear()+" "+today.getHours() + ":" + today.getMinutes() + ":"+ today.getSeconds() }</b>
                    </Grid>
                </Grid> */}

                {/* <div class="box"></div> */}
                {/* <hr style={{border: "1px solid #000000"}} /> */}

                <Grid container spacing={3} style={{fontSize: "12px"}}>
                    <Grid item xs={4}>
                        <b>Name :-    </b>{patientData.patientname}
                    </Grid>
                    {/* <Grid item xs={2}>
                        {patientData.patientname}
                    </Grid> */}

                    <Grid item xs={4}>
                        <b>Age/Sex :-    </b>{patientData.age}/{patientData.gender}
                    </Grid>
                    {/* <Grid item xs={2}>
                        {patientData.patientname}
                    </Grid> */}

                    <Grid item xs={4}>
                    <b>Guardian :-    </b>{patientData.gaurdianname}
                    </Grid>
                    {/* <Grid item xs={2}>
                    {patientData.gaurdianname}
                    </Grid> */}
                </Grid>

                {/* <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <b>Patient Id</b>
                    </Grid>
                    <Grid item xs={3}>
                        {patientData.patientid}
                    </Grid>
                    <Grid item xs={3}>
                    <b>Patient Name</b>
                    </Grid>
                    <Grid item xs={3}>
                        {patientData.patientname}
                    </Grid>
                </Grid> */}

           
             <Grid container spacing={3} style={{fontSize: "12px"}}>
                {/* <Grid item sm={4}>
                    <b>Address :- </b>{patientData.address}
                </Grid> */}

                  <Grid item sm={4}>
                    <b>Doctor :-    </b>{patientData.fname}
                </Grid>
                <Grid item sm={4}>
                    <b>Date  :-    </b>{moment(patientData.appointmentdate).format('DD/MM/YYYY')}
                </Grid>
                <Grid item sm={4}>
                    <b>Valid upto  :-    </b>{moment(patientData.appointmentdate).add(21, 'days').format('DD/MM/YYYY')}
                </Grid>
            </Grid>
            <Grid container style={{fontSize: "12px"}}>
              
                <Grid item sm={8}>
                    <b>Address :- </b>{patientData.address}
                </Grid>
               
            </Grid>
                {/* <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <b>Age</b>
                    </Grid>
                    <Grid item xs={3}>
                    {patientData.age}
                    </Grid>
                    <Grid item xs={3}>
                    <b>Gender</b>
                    </Grid>
                    <Grid item xs={3}>
                    {patientData.gender}
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <b>Consultant Doctor</b>
                    </Grid>
                    <Grid item xs={3}>
                        {patientData.doctorid}
                    </Grid>
                    <Grid item xs={3}>
                    <b>Address</b>
                    </Grid>
                    <Grid item xs={3}>
                        {patientData.address}
                    </Grid>
                </Grid> */}
                {/* <hr style={{border: "1px solid #000000"}} /> */}
                <div class="box"></div>

                <b>O/C</b>

                <div style={{float: "right"}}>
                    <p>Weight...................</p>
                    <p>BP............................</p>
                    <p>O2............................</p>
                    <p>Pulse.......................</p>
                    <p>Temp.......................</p>
                </div>
                <img src={`${API.defaults.baseURL}/uploadimage/footer/${data.footer}`} 
                 style={{ width: '100%', height: '5%', position: 'absolute', bottom: '0', left: '0' }} />
            </div>
            {/* <button className="btn btn-success" style={{marginLeft: "40%"}} onClick={handlePrint}>Print pass</button> */}
        </>
    )
}

export default PrintTemplate