import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../App.css";
import { enc, SHA256 } from "crypto-js"; // Import crypto-js
import {
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MdAccountCircle } from "react-icons/md";
const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const [loginStatus, setLoginStatus] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
useEffect(() => {
  const jwtToken = localStorage.getItem("jwtToken");
  if (jwtToken) {
    // Perform logout action
    localStorage.removeItem("jwtToken");
    setLoginStatus("");
    history.push("/login");
  }
}, []);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: async (values, action) => {
        try {
          const hashedPassword = SHA256(values.password).toString(enc.Hex); // Hash the password using crypto-js

          const response = await axios.post("/AdminLogin", {
            ...values,
            password: hashedPassword,
          });
          console.log(hashedPassword);
          if (response.data.success === true) {
            setLoginStatus(response.data.success);
           // sessionStorage.setItem("jwtToken", response.data.token);
            localStorage.setItem("jwtToken", response.data.token);
            history.push("/dashboard");
          //  window.location.reload();
          } else {
            setSuccess(false);
            setError(true);
          }
        } catch (error) {
          console.log(error);
          alert("An error occurred");
        }

        action.resetForm();
      },
    });

    const currentDomain = window.location.hostname;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container1">
      <div className="image-container1">
        <img src="doctor.svg" alt="Login" className="login-image" />
      </div>
      <div className="form-container1">
        <Typography variant="h5" gutterBottom>
          <img className=" logo" src="logo-aarogya.png" />
        </Typography>
        <div> {success && (
                <div className="alert alert-success">Login successful.</div>
              )}
              {error && (
                <div className="alert alert-danger">Invalid Login!</div>
              )}</div>
        <form  onSubmit={handleSubmit}>
          <div className="text-field">
            <TextField
              fullWidth
              // id="outlined-basic"
              label="Enter your name"
              variant="outlined"
              type="text"
              autoComplete="off"
              name="username"
              id="username"
              placeholder="Enter Your Email"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <MdAccountCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '25vw' }} 
            />
          </div>
          <div className="text-field">
            <TextField
              fullWidth
              // id="outlined-basic"
              label="Enter your password"
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              name="password"
              id="password"
              placeholder="Enter Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '25vw' }} 
            />
          </div>
          <div className="remember-me">
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Remember Me"
            />
          </div>
          <Button
            className="submit-button"
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
          >
            Login
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Login;
