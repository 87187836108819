
import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import { RiScissorsCutLine } from "react-icons/ri";
import { GiMedicines } from "react-icons/gi";
import { ImLab } from "react-icons/im";
// here we are providing the array which will give you the
// title path and all the icons present inside the Sidebar
export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },

  {
    title: "Patient",
    path: "#",
    icon: <FaIcons.FaBed />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "New Patient",
        path: "/newpatientregistration",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Patient List",
        path: "/listviewpatients",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },

  {
    title: " IPD - In Patient ",
    // path: "/ipdlist",
    path: "#",
    icon: <FaIcons.FaBed />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "Admit Patient",
        path: "/patientadmit",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "IPD Patient List",
        path: "/listviewIPD",
        icon: <IoIcons.IoIosPaper />,
      },
         
    ],

  },

  {
    title: "Appointment",
    path: "#",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "New Appointment",
        path: "/newappointment",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Appointment List",
        path: "/appointment",
        icon: <IoIcons.IoIosPaper />,
      },
      
    ],
  },

  {
    title: "Billing",
    path: "#",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "New Billing",
        path: "/billingpage",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Billing List",
        path: "/billing",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },

  {
    title: "  Birth & Death Record ",
    path: "#",
    icon: <FaIcons.FaBirthdayCake />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

       {
         title: "Add Birth Record ",
         path: "/addbirth",
         icon: <IoIcons.IoIosPaper />,
       },

        {
          title: "Birth Record ",
          path: "/listviewbirth",
          icon: <IoIcons.IoIosPaper />,
        },
     
        {
          title: "Death Record",
          path: "#",
  
          icon: <IoIcons.IoIosPaper />,
        },
        
      ],
  },
  {
    title: "Pathology Report",
    path: "/pathologyreport",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Radiology Report",
    path: "/radiologyreportlist",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Report",
    path: "#",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "Appointment Report",
        path: "/appointmentreport",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Billing Report",
        path: "/billingreport",
        icon: <IoIcons.IoIosPaper />,
      },
      
    ],
  },
  {
    title: "Pathology Setup ",
    path: "#",
    icon: <ImLab/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Category List",
        path: "/listviewpathologycategory",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Add Unit",
      //   path: "/addunit",
      //   icon: <IoIcons.IoIosPaper />,
      // },

      {
        title: "Unit List",
        path: "/listviewunit",
        icon: <IoIcons.IoIosPaper />,
      },
      
      {
        title: "Add Parameter",
        path: "/addpathology",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "Pathology Parameter",
        path: "/listviewpathology",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Pathology Report",
      //   path: "/pathologyreport",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  {
    title: "Radiology Setup ",
    path: "#",
    icon: <FaIcons.FaXRay/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Radiology Category  ",
        path: "/listrediologycatagory",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Radiology Unit ",
        path: "/listrediologyunit",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Radiology Parameter ",
        path: "/listviewradiology",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Radiology Report",
      //   path: "/radiologyreportlist",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  {
    title: "Pharmacy ",
    path: "#",
    icon: <GiMedicines  />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

      // {
      //   title: "Medicine Category",
      //   path: "/addmedicinecatagory",
      //   icon: <IoIcons.IoIosPaper />,
      // },
      {
        title: "Category List",
        path: "listmedicinecatagory",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Medicine Unit",
      //   path: "/addmedicineunit",
      //   icon: <IoIcons.IoIosPaper />,
      // },

      {
        title: "Medicine Unit List",
        path: "/listviewmedicineunit",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Supplier",
        path: "/addmedicinemanufacturer",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "Supplier List",
        path: "/listviewmedicinemanufacturer",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Add Medicine",
        path: "/addmedicine",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Medicine List",
        path: "/listmedicine",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Purchase List",
        path: "/pharmacypurchase",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Medicine BillingList",
        path: "/pharmacybillinglist",
        icon: <IoIcons.IoIosPaper />,
      },
    
    ]
    },
    {
      title: "Bed Setup ",
      path: "#",
      icon: <FaIcons.FaBed />,
  
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
  
        
        {
          title: "Bed",
          path: "/listviewbed",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Add Bed",
          path: "/addbed",
          icon: <IoIcons.IoIosPaper />,
        },
  
        {
          title: "Add Floor",
          path: "/addfloor",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Add Bed Group",
          path: "/addbedgroup",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Add Bed Type",
          path: "/addbedtype",
          icon: <IoIcons.IoIosPaper />,
        },
      ],
    },
    {
      title: "Operation Setup ",
      path: "#",
      icon: <RiScissorsCutLine />,
  
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [

        {
          title: "Operation",
          path: "/listviewoperations",
          icon: <IoIcons.IoIosPaper />,
        },
  
        {
          title: "Add Operation",
          path: "/addoperation",
          icon: <IoIcons.IoIosPaper />,
        },
  
        {
          title: "Category List",
          path: "/listviewcategories",
          icon: <IoIcons.IoIosPaper />,
        },
        
        {
          title: "Operation Category",
          path: "/addcategory",
          icon: <IoIcons.IoIosPaper />,
        },
      ],
    },
    {
      title: "Charge Setup ",
      path: "#",
      icon: <FaIcons.FaRupeeSign />,
  
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
  
        
        {
          title: "New Charages",
          path: "/createcharges",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Hospital Charages",
          path: "/allcharges",
          icon: <IoIcons.IoIosPaper />,
        },
       
      ],
    },
  {
    title: "Setup ",
    path: "#",
    icon: <AiIcons.AiFillSetting />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

      {
        title: "New Hospital ",
        path: "/hospital-registration",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Hospital List ",
        path: "/hospital-list",
        icon: <FaIcons.FaFileMedical />,
      },
      {
        title: "Add Staff ",
        path: "/UserRegistration",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Staff List",
        path: "/listviewstaff",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Cancel Bill List",
        path: "/cancelbillinglist",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },

  {
    title: " OPD - Out Patient ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   title: " Pharmacy ",
  //   path: "#",
  //   icon: <MdIcons.MdLocalPharmacy />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Pathology ",
  //   path: "#",
  //   icon: <AiIcons.AiOutlineExperiment />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Radiology ",
  //   path: "#",
  //   icon: <FaIcons.FaXRay />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },

  {
    title: " Blood Bank ",
    path: "#",
    icon: <BiIcons.BiDonateBlood />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: " Ambulance ",
    path: "#",
    icon: <FaIcons.FaAmbulance/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   title: " Hospital ",                      // Hospital Registration and Adding section
  //   path: "#",                         
  //   icon: <FaIcons.FaHospitalAlt />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
     
  //     {
  //       title: "Hospital List ",
  //       path: "/hospital-list",
  //       icon: <FaIcons.FaFileMedical />,
  //     },
  //     {
  //       title: "Hosptial Registration",
  //       path: "/hospital-registration",

  //       icon: <MdIcons.MdLocalHospital />,
  //     },
      
  //   ],
  // },

  
  {
    title: " Front Office ",
    path: "#",
    icon: <HiIcons.HiOutlineOfficeBuilding />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  
  // {
  //   title: " Human Resource ",
  //   path: "#",
  //   icon: <HiIcons.HiUser />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Referral ",
  //   path: "#",
  //   icon: <HiIcons.HiUserGroup />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    title: " TPA Management ",
    path: "#",
    icon: <FaIcons.FaUmbrella />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: " Finance ",
    path: "#",
    icon: <FaIcons.FaMoneyBillWave />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
        {
          title: "Income ",
          path: "#",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Expenses",
          path: "#",
  
          icon: <IoIcons.IoIosPaper />,
        },
        
      ],
  },
  // {
  //   title: " Messaging ",
  //   path: "#",
  //   icon: <MdIcons.MdMessage/>,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Inventory ",
  //   path: "#",
  //   icon: <FaIcons.FaLuggageCart />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Download Center ",
  //   path: "#",
  //   icon: <FaIcons.FaDownload />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    title: " Certificate ",
    path: "#",
    icon: <FaIcons.FaRegNewspaper/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
        {
          title: "Certificate ",
          path: "#",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Patient Id Card",
          path: "#",
          icon: <IoIcons.IoIosPaper />,
        },
        {
            title: "Staff Id Card",
            path: "#",
            icon: <IoIcons.IoIosPaper />,
          },
        
      ],
  },
  {
    title: " Front CMS ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   title: "Live Consultation ",
  //   path: "#",
  //   icon: <BsIcons.BsCameraVideo/>,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
     
  //       {
  //         title: "Live Consultation ",
  //         path: "#",
  //         icon: <IoIcons.IoIosPaper />,
  //       },
  //       {
  //         title: "Live Meeting",
  //         path: "#",
  //         icon: <IoIcons.IoIosPaper />,
  //       },
        
        
  //     ],
  // },
  // {
  //   title: "Reports ",
  //   path: "#",
  //   icon: <FaIcons.FaEnvelopeOpenText />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  

];
export const SidebarDatadropdown = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },

  {
    title: "Patient",
    path: "#",
    icon: <FaIcons.FaBed />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "New Patient",
        path: "/newpatientregistration",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Patient List",
        path: "/listviewpatients",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Patient Admit",
      //   path: "/patientadmit",
      //   icon: <IoIcons.IoIosPaper />,
      // },

      
      
    ],
  },

  {
    title: " IPD - In Patient ",
    // path: "/ipdlist",
    path: "#",
    icon: <FaIcons.FaBed />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "Admit Patient",
        path: "/patientadmit",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "IPD Patient List",
        path: "/listviewIPD",
        icon: <IoIcons.IoIosPaper />,
      },
         
    ],

  },

  {
    title: "Appointment",
    path: "#",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "New Appointment",
        path: "/newappointment",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Appointment List",
        path: "/appointment",
        icon: <IoIcons.IoIosPaper />,
      },
      
    ],
  },

  {
    title: "Billing",
    path: "#",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "New Billing",
        path: "/billingpage",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Billing List",
        path: "/billing",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },

  {
    title: "  Birth & Death Record ",
    path: "#",
    icon: <FaIcons.FaBirthdayCake />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

       {
         title: "Add Birth Record ",
         path: "/addbirth",
         icon: <IoIcons.IoIosPaper />,
       },

        {
          title: "Birth Record ",
          path: "/listviewbirth",
          icon: <IoIcons.IoIosPaper />,
        },
     
        {
          title: "Death Record",
          path: "#",
  
          icon: <IoIcons.IoIosPaper />,
        },
        
      ],
  },
  {
    title: "Pathology Report",
    path: "/pathologyreport",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Radiology Report",
    path: "/radiologyreportlist",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  
  {
    title: "Report",
    path: "#",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "Appointment Report",
        path: "/appointmentreport",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Billing Report",
        path: "/billingreport",
        icon: <IoIcons.IoIosPaper />,
      },
      
    ],
  },
  {
    title: "Pathology Setup ",
    path: "#",
    icon: <ImLab/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

     
      // {
      //   title: "Pathology Category",
      //   path: "/addpathologycategory",
      //   icon: <IoIcons.IoIosPaper />,
      // },
      {
        title: "Category List",
        path: "/listviewpathologycategory",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Add Unit",
      //   path: "/addunit",
      //   icon: <IoIcons.IoIosPaper />,
      // },

      {
        title: "Unit List",
        path: "/listviewunit",
        icon: <IoIcons.IoIosPaper />,
      },
      
      {
        title: "Add Parameter",
        path: "/addpathology",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "Pathology Parameter",
        path: "/listviewpathology",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Pathology Report",
      //   path: "/pathologyreport",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  {
    title: "Radiology Setup ",
    path: "#",
    icon: <FaIcons.FaXRay/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Radiology Category  ",
        path: "/listrediologycatagory",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Radiology Unit ",
        path: "/listrediologyunit",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Radiology Parameter ",
        path: "/listviewradiology",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Radiology Report",
      //   path: "/radiologyreportlist",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  {
    title: "Pharmacy ",
    path: "#",
    icon: <GiMedicines  />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

      // {
      //   title: "Medicine Category",
      //   path: "/addmedicinecatagory",
      //   icon: <IoIcons.IoIosPaper />,
      // },
      {
        title: "Category List",
        path: "listmedicinecatagory",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Medicine Unit",
      //   path: "/addmedicineunit",
      //   icon: <IoIcons.IoIosPaper />,
      // },

      {
        title: "Medicine Unit List",
        path: "/listviewmedicineunit",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Supplier",
        path: "/addmedicinemanufacturer",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "Supplier List",
        path: "/listviewmedicinemanufacturer",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Add Medicine",
        path: "/addmedicine",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Medicine List",
        path: "/listmedicine",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Purchase List",
        path: "/pharmacypurchase",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Medicine BillingList",
        path: "/pharmacybillinglist",
        icon: <IoIcons.IoIosPaper />,
      },
    ]
    },
    {
      title: "Bed Setup ",
      path: "#",
      icon: <FaIcons.FaBed />,
  
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
  
        
        {
          title: "Bed",
          path: "/listviewbed",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Add Bed",
          path: "/addbed",
          icon: <IoIcons.IoIosPaper />,
        },
  
        {
          title: "Add Floor",
          path: "/addfloor",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Add Bed Group",
          path: "/addbedgroup",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Add Bed Type",
          path: "/addbedtype",
          icon: <IoIcons.IoIosPaper />,
        },
      ],
    },
    {
      title: "Operation Setup ",
      path: "#",
      icon: <RiScissorsCutLine />,
  
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [

        {
          title: "Operation",
          path: "/listviewoperations",
          icon: <IoIcons.IoIosPaper />,
        },
  
        {
          title: "Add Operation",
          path: "/addoperation",
          icon: <IoIcons.IoIosPaper />,
        },
  
        {
          title: "Category List",
          path: "/listviewcategories",
          icon: <IoIcons.IoIosPaper />,
        },
        
        {
          title: "Operation Category",
          path: "/addcategory",
          icon: <IoIcons.IoIosPaper />,
        },
      ],
    },
    {
      title: "Charge Setup ",
      path: "#",
      icon: <FaIcons.FaRupeeSign />,
  
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
  
        
        {
          title: "New Charages",
          path: "/createcharges",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Hospital Charages",
          path: "/allcharges",
          icon: <IoIcons.IoIosPaper />,
        },
       
      ],
    },
  {
    title: "Setup ",
    path: "#",
    icon: <AiIcons.AiFillSetting />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

      // {
      //   title: "New Hospital ",
      //   path: "/hospital-registration",
      //   icon: <IoIcons.IoIosPaper />,
      // },
      // {
      //   title: "Hospital List ",
      //   path: "/hospital-list",
      //   icon: <FaIcons.FaFileMedical />,
      // },
    
      {
        title: "Add Staff ",
        path: "/UserRegistration",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Staff List",
        path: "/listviewstaff",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Cancel Bill List",
        path: "/cancelbillinglist",
        icon: <IoIcons.IoIosPaper />,
      },
     
    ],
  },

  
  {
    title: " OPD - Out Patient ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

  },
  {
    title: " Blood Bank ",
    path: "#",
    icon: <BiIcons.BiDonateBlood />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: " Ambulance ",
    path: "#",
    icon: <FaIcons.FaAmbulance/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: " Front Office ",
    path: "#",
    icon: <HiIcons.HiOutlineOfficeBuilding />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "  Birth & Death Record ",
    path: "#",
    icon: <FaIcons.FaBirthdayCake />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [

      {
        title: "Add Birth Record ",
        path: "/addbirth",
        icon: <IoIcons.IoIosPaper />,
      },
     
        {
          title: "Birth Record ",
          path: "/listviewbirth",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Death Record",
          path: "#",
  
          icon: <IoIcons.IoIosPaper />,
        },
        
      ],
  },
  // {
  //   title: " Human Resource ",
  //   path: "#",
  //   icon: <HiIcons.HiUser />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Referral ",
  //   path: "#",
  //   icon: <HiIcons.HiUserGroup />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    title: " TPA Management ",
    path: "#",
    icon: <FaIcons.FaUmbrella />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: " Finance ",
    path: "#",
    icon: <FaIcons.FaMoneyBillWave />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
        {
          title: "Income ",
          path: "#",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Expenses",
          path: "#",
  
          icon: <IoIcons.IoIosPaper />,
        },
        
      ],
  },
  // {
  //   title: " Messaging ",
  //   path: "#",
  //   icon: <MdIcons.MdMessage/>,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Inventory ",
  //   path: "#",
  //   icon: <FaIcons.FaLuggageCart />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  // {
  //   title: " Download Center ",
  //   path: "#",
  //   icon: <FaIcons.FaDownload />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    title: " Certificate ",
    path: "#",
    icon: <FaIcons.FaRegNewspaper/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
        {
          title: "Certificate ",
          path: "#",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Patient Id Card",
          path: "#",
          icon: <IoIcons.IoIosPaper />,
        },
        {
            title: "Staff Id Card",
            path: "#",
            icon: <IoIcons.IoIosPaper />,
          },
        
      ],
  },
  {
    title: " Front CMS ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   title: "Live Consultation ",
  //   path: "#",
  //   icon: <BsIcons.BsCameraVideo/>,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
     
  //       {
  //         title: "Live Consultation ",
  //         path: "#",
  //         icon: <IoIcons.IoIosPaper />,
  //       },
  //       {
  //         title: "Live Meeting",
  //         path: "#",
  //         icon: <IoIcons.IoIosPaper />,
  //       },
        
        
  //     ],
  // },
  // {
  //   title: "Reports ",
  //   path: "#",
  //   icon: <FaIcons.FaEnvelopeOpenText />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  

];
export const Datadropdown = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Appointment",
    path: "/appointment",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: " IPD - In Patient ",
    // path: "/ipdlist",
    path: "#",
    icon: <FaIcons.FaBed />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
     
      {
        title: "Admit Patient",
        path: "/patientadmit",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "IPD Patient List",
        path: "/listviewIPD",
        icon: <IoIcons.IoIosPaper />,
      },
         
    ],

  },
  

];

export const DatadropdownRadiology = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Radiology Report",
    path: "/radiologyreportlist",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Radiology Setup ",
    path: "#",
    icon: <FaIcons.FaXRay/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Radiology Category  ",
        path: "/listrediologycatagory",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Radiology Unit ",
        path: "/listrediologyunit",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Radiology Parameter ",
        path: "/listviewradiology",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Radiology Report",
      //   path: "/radiologyreportlist",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  
 
];
export const DatadropdownPathology = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Pathology Report",
    path: "/pathologyreport",
    icon: <FaIcons.FaRegCalendarCheck />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Pathology Setup ",
    path: "#",
    icon: <ImLab/>,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
    
      {
        title: "Pathology Category",
        path: "/addpathologycategory",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Category List",
        path: "/listviewpathologycategory",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Add Unit",
        path: "/addunit",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "Unit List",
        path: "/listviewunit",
        icon: <IoIcons.IoIosPaper />,
      },
      
      {
        title: "Add Parameter",
        path: "/addpathology",
        icon: <IoIcons.IoIosPaper />,
      },

      {
        title: "Pathology Parameter",
        path: "/listviewpathology",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Pathology Report",
      //   path: "/pathologyreport",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  
 
];